<template>
  <section class=""></section>
</template>
<script>
export default {
  mounted() {
    this.$router.back();
  },
  methods: {},
  data() {
    return {};
  }
};
</script>
